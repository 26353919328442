import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from "vue-router";
import router from "./router"
import axios from 'axios'
//注册全局过滤器
import './filter'
//echarts组件
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.axios = axios
// Vue.prototype.baseUrl = "https://consul.mes.emergen.cn:6012"
// Vue.prototype.baseUrl = "https://consul.mes.emergen.cn:5004"

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

