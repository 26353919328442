import Vue from 'vue'

function date(time) {
  let dateTime = new Date(time);
  let year = dateTime.getFullYear();
  let month = dateTime.getMonth() + 1;
  let date = dateTime.getDate();
  let hour = dateTime.getHours();
  let minute = dateTime.getMinutes();
  let second = dateTime.getSeconds();
  return `${year} `
}
function data(time) {
  let dateTime = new Date(time);
  let year = dateTime.getFullYear();
  let month = dateTime.getMonth() + 1;
  let date = dateTime.getDate();
  let hour = dateTime.getHours();
  let minute = dateTime.getMinutes();
  let second = dateTime.getSeconds();
  return `${year}-${addZero(month)}-${addZero(
    date
  )} ${addZero(hour)}:${addZero(minute)}:${addZero(
    second
  )}`
}
function time(time) {
  let dateTime = new Date(time);
  let year = dateTime.getFullYear();
  let month = dateTime.getMonth() + 1;
  let date = dateTime.getDate();
  return `${addZero(month)}-${addZero(
    date
  )} `
}
function addZero(v) {
  return v < 10 ? "0" + v : v;
}
// addZero(v) {
//   return v < 10 ? "0" + v : v;
// },
// 注册过滤器到vue中
Vue.filter("date", date)
Vue.filter("data", data)
Vue.filter("time", time)