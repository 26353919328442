<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  /* width: 1920px; */
  /* width: 100%; */
  /* max-width: 1920px;
  min-width: 640px; */
  /* min-width: 480px; */
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  /* display: flex; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.el-popover__title {
  font-weight: bold;
}
/* .left {
  width: 30%;
  height: 100%;
  background-color: antiquewhite;
} */
/* .right {
  width: 100%;
  height: 100%;
} */
/* .center {
  width: 100%;
} */
</style>
