import Vue from "vue";
import Router from "vue-router";


Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: "/index",
            component: () => import('../components/view/Index'),
            children: [{
                path: '/index',
                component: () => import('../components/view/Index')
            }]
        },
        {
            path: '/waybill-search',
            component: () => import('../views/waybill-search.vue')
        },
        {
            path: '/line-search',
            component: () => import('../views/line-search.vue')
        },
        {
            path: '/notice',
            component: () => import('../components/Notice.vue')
        },
        {
            path: '/service',
            component: () => import('../components/Service.vue')
        },
        {
            path: '/echarts',
            component: () => import('../components/Echarts.vue')
        }


    ]
})
export default router